import slugify from 'slugify';

import BillingCycleEnum from 'models/billingCycleEnum';
import { Currency, Listing, ListingFeature, Location } from 'models/listing';
import { Feature } from 'models/property';

const formatSlug = (s: string): string => slugify(s, { lower: true, strict: true });

const getViewListingLink = (listing: any): string => {
	const { title } = listing;
	const { city, suburb } = listing.location;

	if (city && suburb && title) {
		const citySlug = formatSlug(city);
		const suburbSlug = formatSlug(suburb);
		const titleSlug = formatSlug(title);
		return `/digs/view/${citySlug}/${suburbSlug}/${titleSlug}/${listing.uuid}`;
	}

	return `/digs/view/${listing.uuid}`;
};

const getSubTypeText = (subType: string): string => {
	switch (subType) {
		case 'residence':
			return 'Student Residence';
		case 'apartment':
			return 'Apartment';
		case 'house':
			return 'House';
		case 'cottage':
			return 'Cottage';
		default:
			return '';
	}
};

const getCurrencySymbol = (currency?: Currency): string => {
	if (!currency || (!currency.symbol && !currency.currency_symbol)) {
		// default to no currency
		return '';
	}

	return currency.currency_symbol || currency.symbol;
};

const getBillingCyclePeriod = (listing: Listing): string => {
	if (listing.billing_cycle === 'monthly') {
		return 'month';
	}
	if (listing.billing_cycle === 'weekly') {
		return 'week';
	}
	return 'month';
};

const getMonthlyLeaseTerm = (listing: Listing): number => {
	if (listing.billing_cycle === BillingCycleEnum.Weekly) {
		if (listing.lease_term === 52) {
			return 12;
		}
		if (listing.lease_term === 26) {
			return 6;
		}
		return Math.round(listing.lease_term / 4);
	}

	return listing.lease_term;
};

const getLocationText = (location: Location): string => {
	if (!location) {
		return '';
	}

	const { city, country, suburb } = location;

	if (suburb && country) {
		return `${suburb}, ${country}`;
	}

	if (city && country) {
		return `${city}, ${country}`;
	}

	return suburb || city || country || '';
};

const hasFeature = (listing: Listing, feature: ListingFeature): boolean => {
	if (!listing.features || !listing.features.length) {
		return false;
	}

	return listing.features.some(({ name, active }) => name.toLowerCase() === feature && active);
};

const getFeatureDisplayName = (feature: Feature, currencySymbol?: string): string => {
	if (
		feature.name === 'Landlord admin fee' &&
		feature.meta.string &&
		currencySymbol &&
		!feature.meta.string.startsWith(currencySymbol)
	) {
		return `${feature.name} (${currencySymbol}${feature.meta.string})`;
	}

	if (
		feature.name === 'Security deposit' &&
		feature.meta.select === 'Enter own value' &&
		feature.meta.string &&
		currencySymbol &&
		!feature.meta.string.startsWith(currencySymbol)
	) {
		return `${feature.name} (${currencySymbol}${feature.meta.string})`;
	}

	if (feature.meta.string) {
		return `${feature.name} (${feature.meta.string})`;
	}

	if (feature.meta.number) {
		return `${feature.name} (${feature.meta.number})`;
	}

	if (feature.meta.select) {
		return `${feature.name} (${feature.meta.select})`;
	}

	return feature.name;
};

export const listingService = {
	getViewListingLink,
	getSubTypeText,
	getCurrencySymbol,
	getBillingCyclePeriod,
	getLocationText,
	getMonthlyLeaseTerm,
	hasFeature,
	getFeatureDisplayName,
};

export default listingService;
