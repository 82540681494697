import { FurnishedType } from 'models/listing';
import { Photo, Room, TenantGender, TenantType } from 'models/property';
import React from 'react';

export enum CreateListingStep {
	Unknown,
	HowTo,
	YourListing,
	Features,
	Occupants,
	Location,
	Pictures,
	NameListing,
	Pricing,
	Preview,
}

export enum CreateListingSubStep {
	Unknown,
	YourListingPropertyType,
	YourListingAvailability,
	YourListingRooms,
	YourListingDetails,
	OccupantsCapacity,
	OccupantsRules,
	LocationAtmosphere,
	LocationAddress,
	PicturesUpload,
	PicturesVideo,
}

export enum StepStatus {
	Unknown,
	Complete,
	Incomplete,
}

export interface CreateListingState {
	termsAccepted: boolean;
	propertyType?: PropertyType;
	leaseType: LeaseType;
	singleRoomCount: number;
	sharedRoomCount: number;
	roomTypeCount: number;
	bedroomCount: number;
	sharedBathrooms: number;
	tenantsPerSharedBathroom: number;
	m2: number | null;
	furnished?: FurnishedType;
	availabilityDate: string | null;
	flexibleMoveInDate: boolean;
	leasePeriodLength: number;
	flexibleLeasePeriod: boolean;
	currentOccupants: number;
	availablePlaces: number;
	totalOccupants: number;
	currentTenantGender: TenantGender | null;
	preferredNewTenantGender: TenantGender | null;
	preferredTenantTypes: TenantType[];
	rooms: Room[];
	address: any;
	photos: Photo[];
	videoLink?: string;
	title: string;
	description: string;
  rentalPeriod: RentalPeriod;
  price: number;
}

export interface StepInfoContent {
	title: string;
	items: {
		header: React.ReactNode | string;
		body: string;
    numberedList?: string[];
	}[];
}

export enum PropertyType {
	Unknown = '',
	House = 'house',
	Apartment = 'apartment',
	Flatlet = 'cottage',
	StudentResidence = 'residence',
}

export enum LeaseType {
	Unknown = '',
	EntirePlace = 'single',
	RoomByRoom = 'multi',
	Any = 'either',
}

export enum BathroomType {
	Unknown = '',
	Ensuite = 'private',
	Shared = 'shared',
}

export enum RentalPeriod {
  Unknown = '',
  Monthly = 'monthly',
  Weekly = 'weekly',
}
