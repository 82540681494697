import React from 'react';

import styled from 'styled-components';
import theme from 'config/theme';
import { BREAKPOINT_TABLET_PX } from 'constants/breakPoints';

import { numberFormatService } from 'services/numberFormatService';
import { FeatureFlag, featureFlagContainer } from 'containers/featureFlags';

const { colors } = theme;

interface ConnectionsCounterProps {
	count: number;
}

const ConnectionsCounter = ({ count }: ConnectionsCounterProps) => {
	return (
		<Container>
			<Number>{numberFormatService.format(count)}</Number>
			<SubHeading>Landlords & tenants connected on DigsConnect</SubHeading>
		</Container>
	);
};

export default ConnectionsCounter;

const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	text-align: center;
	background: ${featureFlagContainer.isEnabled(FeatureFlag.Simplify)
		? colors.grey01
		: `linear-gradient(180deg, ${colors.darkTurquoiseFaded} 0%, ${colors.white} 100%)`};
	padding: 48px 24px;
	gap: 16px;
	@media (max-width: ${BREAKPOINT_TABLET_PX}px) {
		gap: 10px;
	}
`;

const Number = styled.div`
	font-size: 3em;
	font-weight: 700;
	margin-bottom: 16px;
	letter-spacing: 2px;

	@media (max-width: ${BREAKPOINT_TABLET_PX}px) {
		font-size: 40px;
		margin-bottom: 10px;
	}
`;

const SubHeading = styled.div`
	font-size: 24px;
	font-weight: 400;
	margin-bottom: 24px;
	@media (max-width: ${BREAKPOINT_TABLET_PX}px) {
		font-size: 20px;
		margin-bottom: 10px;
	}
`;
